<template>
  <Row id="Login" align="middle">
    <Col class="header" :span="24"></Col>
    <Col class="login-content" :span="24">
      <Row>
        <Col class="pc-display" :sm="6"></Col>
        <Col class="login-form" :xs="24" :sm="12">
          <Row>
            <Col class="login-form-left" :xs="24" :sm="20">
              <Row>
                <Col class="login-form-left-title" :span="12">{{$t("public.name.login")}}</Col>
                <Col class="login-form-left-language" :span="12">
                  <RadioGroup v-model:value="language" size="small" @change="changeLanguage">
                    <RadioButton value="zhTW">中（繁体）</RadioButton>
                    <RadioButton value="enUS">En</RadioButton>
                    <RadioButton value="zhCN">中（简体）</RadioButton>
                  </RadioGroup>
                </Col>
                <Col class="login-form-left-content" :span="24">
                  <Form ref="refLogin" layout="vertical" :model="loginForm" :rules="validateRules">
                    <FormItem :label="$t('public.name.email')+':'" name="email">
                      <Input :placeholder="$t('public.name.email')" v-model:value="loginForm.email">
                        <template #suffix>
                          <UserOutlined />
                        </template>
                      </Input>
                    </FormItem>
                    <FormItem :label="$t('public.name.password')+':'" name="password">
                      <InputPassword :placeholder="$t('public.name.password')" v-model:value="loginForm.password"></InputPassword>
                    </FormItem>
                    <!-- <FormItem :label="$t('public.name.captcha')+':'" name="captcha">
                      <Input :placeholder="$t('public.name.captcha')" v-model:value="loginForm.captcha_code" @keyup.enter="sendLogin">
                        <template #suffix>
                          <Image :width="150" :height="40" :fallback="$store.getters.getNoImage" :src="captchaImg" :preview="false" @click="getCaptcha"></Image>
                        </template>
                      </Input>
                    </FormItem> -->
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col class="login-form-right pc-display" :sm="4" @click="sendLogin">
              <LoginOutlined :style="{ fontSize: '50px' }" />
            </Col>
          </Row>
        </Col>
        <Col :sm="6" class="pc-display"></Col>
      </Row>
    </Col>
    <Col class="footer" :span="24">{{ store.getters.getCopyRight }}</Col>
  </Row>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
}
</script>
<script setup>
import { Row, Col, Form, FormItem, Input, InputPassword, RadioGroup, RadioButton, message } from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";
import { Login } from "@/api/employee"
import {getCurrentInstance, onMounted, onUnmounted, reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const router = useRouter()
const store = useStore()
const i18n = useI18n()

const { proxy } = getCurrentInstance();

let captchaImg = ref("")
let captchaExpire = ref(0)
let language = ref("zhTW")
// 防重复点击
let clickState = ref(false)

let refLogin = ref()

const validateRules = {
  email: [
    {
      required: true,
      // type: "email",
      message: i18n.t("login.validate.required.email"),
      trigger: "blur",
    },
  ],
  password: [
    {
      required: true,
      message: i18n.t("login.validate.required.password"),
      trigger: "blur",
    },
  ],
  // captcha_code: [
  //   {
  //     required: true,
  //     message: i18n.t("login.validate.required.captcha"),
  //     trigger: "blur",
  //   },
  // ],
}

const data = reactive({
  loginForm: {
    email: "",
    password: "",
    // captcha_code: "",
    // captcha_key: "",
  }
})

const {loginForm} = toRefs(data)

function getCaptcha() {
  // const api = new Captcha();
  // api.get({ type: 1 }).then((response, any) => {
  //   if (1 == parseInt(response.data.code)) {
  //     this.captchaImg = response.data.data.image;
  //     this.login.captcha_key = response.data.data.key;
  //     this.captchaExpire = parseInt(response.data.data.expire_time);
  //   } else {
  //     this.captchaImg = "";
  //     this.login.captcha_key = "";
  //     this.captchaExpire = 0;
  //   }
  // });
}

function changeLanguage() {
  switch (language.value) {
    case "zhTW":
      i18n.locale = "zhTW";
      store.commit("setLanguage", "zhTW");
      break;
    case "enUS":
      i18n.locale = "enUS";
      store.commit("setLanguage", "enUS");
      break;
    case "zhCN":
      i18n.locale = "zhCN";
      store.commit("setLanguage", "zhCN");
      break;
  }
}

function sendLogin() {
  refLogin.value.validate().then(() => {
        const api = new Login();
        api.post(loginForm.value).then((response, any) => {
          if (0 === parseInt(response.data.code)) {
            store.commit("setToken", response.data.data.token);
            store.commit("setUsername", response.data.data.name);
            store.commit("setIdRol", response.data.data.idRol);
            store.commit("setUserType", response.data.data.userType);
            store.commit("setPermissionList", response.data.data.permissionList);
            store.commit("setDsKey",response.data.data.tenantKey);
            if(response.data.msg != null && response.data.msg !== ''){
              message.success(response.data.msg);
            }
            router.push({ name: "Dashboard" });
          } else {
            message.error(response.data.msg);
            // this.getCaptcha();
          }
        });
      }).catch();
}

function checkLogin() {
  // const api = new apiLogin();
  // api.get().then((response, any) => {
  //   if (1 == parseInt(response.data.code)) {
  //     this.$store.commit("setName", response.data.data.name);
  //     this.$store.commit("setUsername", response.data.data.username);
  //     this.$store.commit("setPortrait", response.data.data.portrait);
  //     this.$store.commit("setLastIp", response.data.data.last_ip);
  //     this.$store.commit("setLastTime", response.data.data.last_time);
  //     this.$router.push({ name: "Dashboard" });
  //   } else {
  //     this.getCaptcha();
  //   }
  // });
}
// 监听键盘回车事件
function keyDown(e){
  // 防止用户重复点击回车
  if (e.keyCode === 13 && clickState.value) {
    message.error('操作过于频繁');
  }
  if(e.keyCode === 13 && !clickState.value){
    clickState.value = true
    sendLogin();
    // 延迟执行
    setTimeout(()=>{
      clickState.value = false
    }, 1000);
  }
}

onMounted(()=>{
  // 绑定enter事件
  window.addEventListener('keydown', keyDown);

  // 根据url后缀判断是否租户标识，当前服务暂不需要该逻辑，应从用户信息中获取
  // let dsKey = window.location.pathname;
  // dsKey = dsKey.replaceAll("/","")
  // console.log(dsKey)
  // this.$store.commit("setDsKey",dsKey);
  checkLogin();
  language.value = store.getters.getLanguage;

  // 按回车键响应
  document.onkeydown = function() {
    let key = window.Event.keyCode;
    if (key === 13 && !clickState.value) {
      sendLogin()
    }
  };
})

onUnmounted(()=>{
  // 销毁回车事件的监听
  window.removeEventListener('keydown',keyDown,false);
})
</script>


<style lang="less">
@import url("style.less");
</style>
