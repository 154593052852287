<template>
  <Modal
      id="SetChargingProfile"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @cancel="visible = !visible"
      @ok="submit"
      :title="$t('public.name.edit')"
  >
    <Row align="middle" justify="center">
      <Col span="18">
        <Row>
          <Col span="24">
            <Form
                ref="refForm"
                :model="form"
                layout="vertical"
                :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('pileManage.field.identifier')" name="identifier">
                    <Input :placeholder="$t('pileManage.validate.identifier')" v-model:value="form.identifier" disabled></Input>
                  </FormItem>
                </Col>

                <Col span="10"></Col>

                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.cPort')" name="port">
                    <Input :placeholder="$t('chargerManage.validate.cPort')" v-model:value="form.port" disabled></Input>
                  </FormItem>
                </Col>

                <Col span="10">
                  <FormItem :label="$t('chargerManage.table.powerLimit')" name="powerLimit">
                    <InputNumber :precision="1" :placeholder="$t('chargerManage.validate.powerLimit')" v-model:value="form.powerLimit"></InputNumber>
                  </FormItem>
                </Col>

              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>
<script>
export default {
  name: "SetChargingProfile",
}
</script>
<script setup>
import {
  Modal,
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Textarea,
  message,
  InputNumber
} from "ant-design-vue";

import {ChargerSetChargingProfile} from "@/api/charger";
import {reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()

const emits = defineEmits(['updateList'])

const refForm = ref()

let confirmLoading = ref(false)
let visible = ref(false)

let resData = ref("")

const validateRules = {
  identifier: [
    {
      required: true,
      message: i18n.t("pileManage.required.identifier"),
      trigger: "change",
    },
  ],
  port: [
    {
      required: true,
      message: i18n.t("chargerManage.validate.cPort"),
      trigger: "change",
    },
  ],
  powerLimit: [
    {
      required: false,
      message: i18n.t("chargerManage.validate.powerLimit"),
      trigger: "change",
    },
  ],
}

const data = reactive({
  form: {
    identifier: undefined,
    port: undefined,
    powerLimit: undefined,
  },
})
const {form} = toRefs(data)

async function editShow(identifier,port) {
  _resetData();
  form.value.identifier = identifier
  form.value.port = port
  visible.value = true;
}

function submit() {
  refForm.value.validate().then(() => {
    confirmLoading.value = true
    const api = new ChargerSetChargingProfile();
    api.get({identifier: form.value.identifier, port: form.value.port, powerLimit: form.value.powerLimit}).then((res) => {
      confirmLoading.value = false
      if (0 === parseInt(res.data.code)) {
        message.success(res.data.msg);
        // 延迟执行
        setTimeout(() => {
          visible.value = false;
          emits("updateList", res.data.data);
        }, 1000);
      } else {
        message.error(res.data.msg);
      }
    })
  }).catch(() => {});
}

function _resetData() {
  resData.value = undefined
  form.value = {
    identifier: undefined,
    messageId: undefined,
    dataStr: undefined,
  };
}

// eslint-disable-next-line no-undef
defineExpose({
  editShow
})
</script>
