import {Base} from "@/api/Base"

const api = "tuya/devices"

// 获取全部设备列表
export class DevicesList extends Base {
    path = api + "/getDeviceListByProject"
}

// 获取设备状态上报日志
export class DevicesStatusLog extends Base {
    path = api + "/getDeviceStatusLog"
}

// 获取设备操作日志
export class DeviceOperaLog extends Base {
    path = api + "/getDeviceOperaLog"
}

// 获取设备规格属性
export class DeviceSpecificationAttributes extends Base {
    path = api + "/getDeviceSpecificationAttributes"
}

// 获取产品品类列表
export class DeviceCategories extends Base {
    path = api + "/getDeviceCategories"
}

// 获取设备状态集
export class DeviceStatusSet extends Base {
    path = api + "/getStatusSet"
}

// 获取设备状态
export class DeviceStatus extends Base {
    path = api + "/getDeviceStatus"
}

// 获取设备支持的指令集
export class DeviceFunctions extends Base {
    path = api + "/getDeviceFunctions"
}

// 下发属性
export class DeviceSendProperties extends Base {
    path = api + "/sendProperties"
}

// 下发动作
export class DeviceSendAction extends Base {
    path = api + "/sendAction"
}

// 下发指令
export class DeviceSendFunction extends Base {
    path = api + "/sendFunction"
}


