<template>
  <Modal id="BalanceLogDetail" width="60%" :open="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('public.name.detail')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('DeviceManage.table.activeTime')">{{data.activeTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.bindSpaceId')">{{data.bindSpaceId}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.category')">{{data.category}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.createTime')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.customName')">{{data.customName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.icon')">{{data.icon}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.id')">{{data.id}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.ip')">{{data.ip}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.isOnline')">{{data.isOnline}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.lat')">{{data.lat}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.lon')">{{data.lon}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.localKey')">{{data.localKey}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.model')">{{data.model}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.name')">{{data.name}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.productId')">{{data.productId}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.productName')">{{data.productName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.sub')">{{data.sub}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.timeZone')">{{data.timeZone}}</DescriptionsItem>

      <DescriptionsItem :label="$t('DeviceManage.table.updateTime')">{{data.updateTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('DeviceManage.table.uuid')">{{data.uuid}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>
<script>
export default {
  name: "DetailModel"
}
</script>
<script setup>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import {reactive, ref} from "vue";
import {useI18n} from 'vue-i18n'

const i18n = useI18n()
let visible = ref(false)
let data = ref({})

async function detailShow(obj) {
  data.value = obj;
  visible.value = true;
}
function handelNull(v) {
  return v === undefined || v === null || v === '0' || v === 0 ? '-' : v
}

defineExpose({
  detailShow,
});
</script>

<style lang="less">
</style>