<template>
  <div id="Dashboard">
    <Row :gutter="[16,16]">
      <Col style="width: 100%">
        <Card title="概览" style="width: 100%;">
          <Card>
            <CardGrid style="text-align: left">
              <Statistic :title="$t('dashboard.name.totalChargers')" :value="allPileCount" style="margin-right: 50px"/>
              <Progress :percent="100"  :format="() => '100%'" :showInfo="false" />
              100%
            </CardGrid>
            <CardGrid style="text-align: left">
              <Statistic title="离线桩数" :value="offlinePileCount" style="margin-right: 50px"/>
              <Progress :percent="offlinePileCount / allPileCount * 100" status="exception"  :showInfo="false"/>
              {{ (allPileCount === 0 ? 0 : offlinePileCount / allPileCount * 100).toFixed(2) }}%
            </CardGrid>
            <CardGrid style="text-align: left">
              <Statistic title="在线桩数" :value="onlinePileCount" style="margin-right: 50px"/>
              <Progress :percent="onlinePileCount / allPileCount * 100" :showInfo="false"/>
              {{ (allPileCount === 0 ? 0 : onlinePileCount / allPileCount * 100).toFixed(2) }}%
            </CardGrid>
          </Card>
          <Card>

            <CardGrid style="width: 20%; text-align: left">
              <Statistic title="总端口数" :value="allChargerCount" style="margin-right: 50px"/>
              <Progress :percent="100" size="small" :showInfo="false"/>
              100%
            </CardGrid>
            <CardGrid style="width: 20%; text-align: left">
              <Statistic title="离线端口数" :value="offlineChargerCount" style="margin-right: 50px"/>
              <Progress :percent="offlineChargerCount / allChargerCount * 100" status="exception" :showInfo="false"/>
              {{ (allChargerCount === 0 ? 0 : offlineChargerCount / allChargerCount * 100).toFixed(2) }}%
            </CardGrid>
            <CardGrid style="width: 20%; text-align: left">
              <Statistic title="在用端口数" :value="useChargerCount" style="margin-right: 50px"/>
              <Progress :percent="useChargerCount / onlineChargerCount * 100" :showInfo="false"/>
              {{ (onlineChargerCount === 0 ? 0 : useChargerCount / allChargerCount * 100).toFixed(2) }}%
            </CardGrid>
            <CardGrid style="width: 20%; text-align: left">
              <Statistic title="空闲端口数" :value="idleChargerCount" style="margin-right: 50px"/>
              <Progress :percent="idleChargerCount / onlineChargerCount * 100" :showInfo="false"/>
              {{ (onlineChargerCount === 0 ? 0 : idleChargerCount / allChargerCount * 100).toFixed(2) }}%
            </CardGrid>
            <CardGrid style="width: 20%; text-align: left">
              <Statistic title="异常端口数" :value="errChargerCount" style="margin-right: 50px"/>
              <Progress :percent="errChargerCount / onlineChargerCount * 100" status="exception"  :showInfo="false"/>
              {{ (onlineChargerCount === 0 ? 0 : errChargerCount / allChargerCount * 100).toFixed(2) }}%
            </CardGrid>
          </Card>
        </Card>
      </Col>

      <Col style="width: 50%">
        <Card title="总电桩">
          <template #extra>
            <!--            <DatePicker @change="getStatisticEnergyUsage" picker="year" :placeholder="this.$t('public.promptsToSelectTime')" />-->
          </template>
          <div id="pilePie"></div>
        </Card>
      </Col>
      <Col style="width: 50%">
        <Card title="总端口">
          <template #extra>
            <!--            <DatePicker @change="getStatisticEnergyUsage" picker="year" :placeholder="this.$t('public.promptsToSelectTime')" />-->
          </template>
          <div id="chargerPie"></div>
        </Card>
      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
}
</script>
<script setup>
import {
  Card,
  CardGrid,
  Statistic,
  Progress,
  Row,
  Col,
} from "ant-design-vue";

import {Pie} from '@antv/g2plot';
import {onMounted, ref} from "vue";
import {ChargerGetDashboard} from "@/api/charger";


let allUnavaliableChargingStationNum = ref(0)

let allChargingStationNum = ref(0)

let tenantKey = ref("admin")
// 总桩数量
let allPileCount = ref(0)
// 离线桩数量
let offlinePileCount = ref(0)
// 在线桩数量
let onlinePileCount = ref(0)
// 空闲桩数量
let idlePileCount = ref(0)
// 使用中桩数量
let usePileCount = ref(0)
// 异常桩数量
let errPileCount = ref(0)

// 总端口数量
let allChargerCount = ref(0)
// 离线端口数量
let offlineChargerCount = ref(0)
// 在线端口数量
let onlineChargerCount = ref(0)
// 空闲端口数量
let idleChargerCount = ref(0)
// 在用端口数量
let useChargerCount = ref(0)
// 异常端口数量
let errChargerCount = ref(0)

let piePileList = ref()
let pieChargerList = ref()

function getInit() {
  // 获取头部总计
  getHeardCount()
}
function getHeardCount() {
  const api = new ChargerGetDashboard()
  api.get({tenantKey: tenantKey.value}).then(res=>{
    const param = res.data.data
    allPileCount.value = param.allPileCount
    offlinePileCount.value = param.offlinePileCount
    onlinePileCount.value = param.onlinePileCount
    idlePileCount.value = param.idlePileCount
    usePileCount.value = param.usePileCount
    errPileCount.value = param.errPileCount

    allChargerCount.value = param.allChargerCount
    offlineChargerCount.value = param.offlineChargerCount
    onlineChargerCount.value = param.onlineChargerCount
    idleChargerCount.value = param.idleChargerCount
    useChargerCount.value = param.useChargerCount
    errChargerCount.value = param.errChargerCount

    piePileList.value.changeData(param.piePileList)
    pieChargerList.value.changeData(param.pieChargerList)
  })
}

function initPilePie() {
  piePileList.value = new Pie('pilePie', {
    appendPadding: 10,
    data: [],
    angleField: 'number',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `¥ ${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: {textAlign: 'center'},
      formatter: ({percent}) => `${(percent * 100).toFixed(2)}%`,
    },
    statistic: {
      title: {
        offsetY: -8,
      },
      content: {
        offsetY: -4,
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
      {
        type: 'pie-statistic-active',
        cfg: {
          start: [
            {trigger: 'element:mouseenter', action: 'pie-statistic:change'},
            {trigger: 'legend-item:mouseenter', action: 'pie-statistic:change'},
          ],
          end: [
            {trigger: 'element:mouseleave', action: 'pie-statistic:reset'},
            {trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset'},
          ],
        },
      },
    ],
  });
  piePileList.value.render();
}
function initChargerPie() {
  pieChargerList.value = new Pie('chargerPie', {
    appendPadding: 10,
    data: [],
    angleField: 'number',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `¥ ${v}`,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      autoRotate: false,
      style: {textAlign: 'center'},
      formatter: ({percent}) => `${(percent * 100).toFixed(2)}%`,
    },
    statistic: {
      title: {
        offsetY: -8,
      },
      content: {
        offsetY: -4,
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {type: 'element-selected'},
      {type: 'element-active'},
      {
        type: 'pie-statistic-active',
        cfg: {
          start: [
            {trigger: 'element:mouseenter', action: 'pie-statistic:change'},
            {trigger: 'legend-item:mouseenter', action: 'pie-statistic:change'},
          ],
          end: [
            {trigger: 'element:mouseleave', action: 'pie-statistic:reset'},
            {trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset'},
          ],
        },
      },
    ],
  });
  pieChargerList.value.render();
}

function initChart() {
  initPilePie()
  initChargerPie()
}

onMounted(()=>{
  initChart()
  getInit()
})

</script>
<style lang="less">
@import url("style.less");
</style>

