const zhCN = {
    public: {
        name: {
            index: "序号",
            ID: "ID",
            status: "状态",
            login: "登录",
            logout: "登出",
            hello: "你好",
            username: "用户名",
            email: "E-mail",
            password: "密码",
            confirm_password: "确认密码",
            reset_password: "重置密码",
            captcha: "验证码",
            more: "更多",
            add: "新增",
            edit: "编辑",
            del: "删除",
            setting: "设置",
            dels: "批量删除",
            detail: "详情",
            yes: "是",
            no: "否",
            empty: "空",
            are_you_sure_delete: "你确定需要删除该信息吗",
            action_cannot_resumed: "该操作将无法恢复",
            are_you_sure_operate: "是否确定当前操作",
            operate_remind: "当前操作敏感，请谨慎",
            search: "搜索",
            reset: "重置",
            spread: "展开",
            retract: "收回",
            show: "展示",
            no_page: "无页面",
            service: "服务商",
            phone_country_code: "电话国家代码",
            name: "名称",
            phone: "电话",
            sex: "性别",
            country: "国家",
            address: "地址",
            birthday: "出生年月日",
            remark: "备注",
            personal: "个人信息",
            enclosure: "附件",
            logo: "Logo",
            submit: "提交",
            batchDelete: "批量删除",
            areYouSure:"是否确认",
            operateRemind: "当前操作敏感，请慎重",
        },
        language: {
            zhTW: "中文(繁体)",
            enUS: "英文",
            zhCN: "中文(简体)"
        },
        isTrue: {
            0: "否",
            1: "是",
        },
        status: {
            0: "未知",
            1: "正常",
            2: "禁用",
            3: "已删除",
        },
        sex: {
            1: "男",
            2: "女",
            3: "未知"
        },
        placeholder: {
            please_select: "请选择",
        },
        table: {
            name: "名称",
            status: "状态",
            action: "操作",
            content: "内容",
            created_at: "创建时间",
            image: "图像",
            email: "E-mail",
            order_id: "排序",
            sex: "性别",
            last_ip: "最后登录IP",
            phone: "电话",
        },
        field: {
            name: "名称",
            username: "用户名",
            content: "内容",
            phone_country_code: "电话国家代码",
            phone: "电话",
            email: "E-mail",
            order_id: "排序",
            portrait: "头像",
            status: "状态",
            image: "图像",
            sex: "性别",
        },
        validate: {
            required: {
                id: "缺少ID",
                username: "请输入用户名",
                password: "请输入密码",
                repassword: "请重复密码",
                verify_password: "请重复密码",
                name: "请输入名称",
                phone: "请输入电话",
                email: "请输入E-mail",
                portrait: "请上传头像",
                phone_country_code: "请选择电话国家代码",
            },
            tips: {
                inconsistent_password: "密码不一致",
                length_should: "长度应为{min}至{max}"
            }
        }
    },
    language: {
        zhTW: "中文（繁体）",
        enUS: "英语",
        zhCN: "中文（简体）"
    },
    nav: {
        name: {
            Dashboard: "仪錶盘",
            AuthorityManage: "权限管理",
            TenantManage: "租户管理",
            BalanceLogManage: "账变记录管理",
            CarManage: "车辆管理",
            CarImagesManage: "车图管理",
            ChargeStationImagesManage: "充电桩图片管理",
            ChargingStationManage: "充电桩管理",
            ConnectorManage: "连接端管理",
            EmployeeManage: "用户管理",
            EventLogManage: "事件记录管理",
            MeterManage: "电表管理",
            OrderManage: "订单管理",
            PlanManage: "充电计划管理",
            RegionManage: "区域管理",
            RegionImagesManage: "充电桩图片管理",
            RepairLogManage: "维修记录管理",
            RoleManage: "角色管理",
            TransactionLogManage: "事务记录管理",
            UserManage: "用户管理",
            UserPlanManage: "用户计划管理",
            GetConfiguration: "获取电桩配置信息",
            DataTransfer: "设定电桩参数",
            TenantManagement: "系统管理",
            ChargerSetting: "充电桩设定",
            AppManagement: "App数据管理",
            LogManagement: "日志管理",
            AppVersionManage: "App版本管理",
            RentalManage: "客户管理",
            PileManage: "充电桩管理",
            ChargerManage: "电枪管理",
            TradeManage: "充电记录管理",

            TuyaManagement: "Tuya",
            DeviceManage: "设备管理",
            DeviceLogs: "设备日志",
        },
        table: {
            order_id: "排序",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "排序",
            name: "名称",
            url: "Url",
            status: "状态",
            icon: "图标",
        },
    },
    login: {
        validate: {
            required: {
                username: "请输入用户名",
                email: "请输入邮箱",
                password: "请输入密码",
                captcha: "请输入验证码",
            }
        }
    },
    authority: {
        name: {
            title: "权限",
            detailTitle: "权限详情",
            editTitle: "编辑权限"
        },
        table: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
            sort: "排序",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "父权限",
            name: "权限",
            resource_name: "权限标识",
            type: "权限类型",
            sort: "排序",
        },
        validate: {
            required: {
                id_aut: "请填写ID",
                a_id_aut: "请填写父权限",
                name: "请填写权限",
                resource_name: "请填写权限标识",
                type: "请填写权限类型",
                sort: "请填写排序",
            }
        },
    },
    balanceLog: {
        name: {
            title: "账变记录",
            detailTitle: "账变记录详情",
            editTitle: "编辑账变记录"
        },
        table: {
            id_bal_log: "ID",
            id_use: "用户",
            type: "类型",
            amount: "金额",
            log_time: "时间",
            tenant_key: "租户识别码",
            status: "状态",
            payment_method: "支付方式",
        },
        field: {
            id_bal_log: "ID",
            id_use: "用户",
            type: "类型",
            amount: "金额",
            log_time: "时间",
            tenant_key: "租户识别码",
            status: "状态",
            payment_method: "支付方式",
        },
        validate: {
            required: {
                id_bal_log: "请填写ID",
                id_use: "请填写用户",
                type: "请填写类型",
                amount: "请填写金额",
                log_time: "请填写时间",
                tenant_key: "请填写租户识别码",
                status: "请填写状态",
                payment_method: "请填写支付方式",
            }
        },
    },
    car: {
        name: {
            title: "车辆",
            detailTitle: "车辆详情",
            editTitle: "编辑车辆"
        },
        table: {
            id_car: "ID",
            id_use: "用户",
            model: "型号",
            car_license: "牌照",
            max_years_old: "最大年份",
            manufacture_year: "制造年份",
            maximum_power: "最大容量",
            is_second_hand: "是否二手",
            vehicle_inspection_date: "检修日期",
            insurance_expiry_date: "保险到期日",
            tenant_key: "租户识别码",
        },
        field: {
            id_car: "ID",
            id_use: "用户",
            model: "型号",
            car_license: "牌照",
            max_years_old: "最大年份",
            manufacture_year: "制造年份",
            maximum_power: "最大容量",
            is_second_hand: "是否二手",
            vehicle_inspection_date: "检修日期",
            insurance_expiry_date: "保险到期日",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_car: "请填写ID",
                id_use: "请填写用户",
                model: "请填写型号",
                car_license: "请填写牌照",
                max_years_old: "请填写最大年份",
                manufacture_year: "请填写制造年份",
                maximum_power: "请填写最大容量",
                is_second_hand: "请填写是否二手",
                vehicle_inspection_date: "请填写检修日期",
                insurance_expiry_date: "请填写保险到期日",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    carImages: {
        name: {
            title: "车图",
            detailTitle: "车图详情",
            editTitle: "编辑车图"
        },
        table: {
            id_car_ima: "ID",
            id_car: "车辆",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        field: {
            id_car_ima: "ID",
            id_car: "车辆",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_car_ima: "请填写ID",
                id_car: "请填写车辆",
                url: "请填写链接",
                upload_time: "请填写上传时间",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    chargeStationImages: {
        name: {
            title: "充电桩图片",
            detailTitle: "充电桩图片详情",
            editTitle: "编辑充电桩图片"
        },
        table: {
            id_ima: "ID",
            id_cha_poi: "所属电桩",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        field: {
            id_ima: "ID",
            id_cha_poi: "所属电桩",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_ima: "请填写ID",
                id_cha_poi: "请填写所属电桩",
                url: "请填写链接",
                upload_time: "请填写上传时间",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    chargingStation: {
        name: {
            title: "充电桩",
            detailTitle: "充电桩详情",
            editTitle: "编辑充电桩",
            quickAdd: "添加充电桩",
        },
        table: {
            id_cha_poi: "ID",
            id_met: "所属电表",
            charge_box_id: "电桩ID",
            last_heartbeat_time: "上次心跳时间",
            monthly_fee: "系统接入月费",
            charger_type: "充电桩类型",
            tenant_key: "租户识别码",
            name: "名称",
            heartbeat_interval: "心跳间隔",
            enable: "是否可用",
        },
        field: {
            id_cha_poi: "ID",
            id_met: "所属电表",
            charge_box_id: "电桩ID",
            last_heartbeat_time: "上次心跳时间",
            monthly_fee: "系统接入月费",
            charger_type: "充电桩类型",
            tenant_key: "租户识别码",
            name: "名称",
            heartbeat_interval: "心跳间隔",
            enable: "是否可用",
        },
        validate: {
            required: {
                id_cha_poi: "请填写ID",
                id_met: "请填写所属电表",
                charge_box_id: "请填写电桩ID",
                last_heartbeat_time: "请填写上次心跳时间",
                monthly_fee: "请填写系统接入月费",
                charger_type: "请填写充电桩类型",
                tenant_key: "请填写租户识别码",
                name: "请填写名称",
                heartbeat_interval: "请填写心跳间隔",
                enable: "请填写是否可用",
            }
        },
    },
    connector: {
        name: {
            title: "连接端",
            detailTitle: "连接端详情",
            editTitle: "编辑连接端",
            startTransaction: "开始充电",
            stopTransaction: "停止充电",
        },
        table: {
            id_con: "ID",
            id_cha_poi: "所属电桩",
            name: "名称",
            connector_id: "端口ID",
            status: "连接端状态",
            tenant_key: "租户识别码",
            power: "电量",
            type: "类型",
        },
        field: {
            id_con: "ID",
            id_cha_poi: "所属电桩",
            name: "名称",
            connector_id: "端口ID",
            status: "连接端状态",
            tenant_key: "租户识别码",
            power: "电量",
            type: "类型",
        },
        validate: {
            required: {
                id_con: "请填写ID",
                id_cha_poi: "请填写所属电桩",
                name: "请填写名称",
                connector_id: "请填写端口ID",
                status: "请填写连接端状态",
                tenant_key: "请填写租户识别码",
                power: "请填写电量",
                type: "请填写类型",
            }
        },
    },
    employee: {
        name: {
            title: "用户",
            detailTitle: "用户详情",
            editTitle: "编辑用户"
        },
        table: {
            id_emp: "ID",
            id_rol: "角色",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            email: "邮箱",
            remark: "备注",
            status: "状态",
            name: "姓名",
            commission_rate: "电价分成比例",
            yedpay_api_key: "Yedpay API密钥",
            yedpay_sign_key: "Yedpay 签名密钥",
            tenant_key: "租户识别码",
        },
        field: {
            id_emp: "ID",
            id_rol: "角色",
            account: "账号",
            password: "密码",
            salt: "盐",
            last_login_time: "上次登录时间",
            register_time: "创建时间",
            login_failure_counter: "登录错误计数",
            email: "邮箱",
            remark: "备注",
            status: "状态",
            name: "姓名",
            commission_rate: "电价分成比例",
            yedpay_api_key: "Yedpay API密钥",
            yedpay_sign_key: "Yedpay 签名密钥",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_emp: "请填写ID",
                id_rol: "请填写角色",
                account: "请填写账号",
                password: "请填写密码",
                salt: "请填写盐",
                last_login_time: "请填写上次登录时间",
                register_time: "请填写创建时间",
                login_failure_counter: "请填写登录错误计数",
                email: "请填写邮箱",
                remark: "请填写备注",
                status: "请填写状态",
                name: "请填写姓名",
                commission_rate: "请填写电价分成比例",
                yedpay_api_key: "请填写Yedpay API密钥",
                yedpay_sign_key: "请填写Yedpay 签名密钥",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    eventLog: {
        name: {
            title: "事件记录",
            detailTitle: "事件记录详情",
            editTitle: "编辑事件记录"
        },
        table: {
            id_env_log: "ID",
            event_time: "事件时间",
            log: "日志",
            tenant_key: "租户识别码",
        },
        field: {
            id_env_log: "ID",
            event_time: "事件时间",
            log: "日志",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_env_log: "请填写ID",
                event_time: "请填写事件时间",
                log: "请填写日志",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    meter: {
        name: {
            title: "电表",
            detailTitle: "电表详情",
            editTitle: "编辑电表"
        },
        table: {
            id_met: "ID",
            id_reg: "所属区域",
            name: "电表名称",
            current_threshold: "电流阈值",
            tenant_key: "租户识别码",
        },
        field: {
            id_met: "ID",
            id_reg: "所属区域",
            name: "电表名称",
            current_threshold: "电流阈值",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_met: "请填写ID",
                id_reg: "请填写所属区域",
                name: "请填写电表名称",
                current_threshold: "请填写电流阈值",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    order: {
        name: {
            title: "订单",
            detailTitle: "订单详情",
            editTitle: "编辑订单"
        },
        table: {
            id_ord: "ID",
            id_car: "车辆",
            id_con: "所属连接端",
            order_time: "订单日期",
            type: "订单类型",
            amount: "金额",
            charge_start_time: "充电开始时间",
            charge_end_time: "充电结束时间",
            status: "状态",
            payment_time: "支付时间",
            electricity: "充电量",
            ref_id_pla: "关联计划",
            price: "下单时电价",
            commission_rate: "下单时的分成比例",
            tenant_key: "租户识别码",
            id_tra_log: "充电关联记录",
            charge_minutes: "充电时长",
            pay_type: "支付类型",
            payment_method: "支付方式",
        },
        field: {
            id_ord: "ID",
            id_car: "车辆",
            id_con: "所属连接端",
            order_time: "订单日期",
            type: "订单类型",
            amount: "金额",
            charge_start_time: "充电开始时间",
            charge_end_time: "充电结束时间",
            status: "状态",
            payment_time: "支付时间",
            electricity: "充电量",
            ref_id_pla: "关联计划",
            price: "下单时电价",
            commission_rate: "下单时的分成比例",
            tenant_key: "租户识别码",
            id_tra_log: "充电关联记录",
            charge_minutes: "充电时长",
            pay_type: "支付类型",
            payment_method: "支付方式",
        },
        validate: {
            required: {
                id_ord: "请填写ID",
                id_car: "请填写车辆",
                id_con: "请填写所属连接端",
                order_time: "请填写订单日期",
                type: "请填写订单类型",
                amount: "请填写金额",
                charge_start_time: "请填写充电开始时间",
                charge_end_time: "请填写充电结束时间",
                status: "请填写状态",
                payment_time: "请填写支付时间",
                electricity: "请填写充电量",
                ref_id_pla: "请填写关联计划",
                price: "请填写下单时电价",
                commission_rate: "请填写下单时的分成比例",
                tenant_key: "请填写租户识别码",
                id_tra_log: "请填写充电关联记录",
                charge_minutes: "请填写充电时长",
                pay_type: "请填写支付类型",
                payment_method: "请填写支付方式",
            }
        },
    },
    plan: {
        name: {
            title: "充电计划",
            detailTitle: "充电计划详情",
            editTitle: "编辑充电计划"
        },
        table: {
            id_pla: "ID",
            name: "计划名称",
            price_per_month: "价格",
            contract_period: "合同期限",
            free_charging_time_limit: "免费充电时间",
            overtime_charge_price: "超时价格",
            tenant_key: "租户识别码",
        },
        field: {
            id_pla: "ID",
            name: "计划名称",
            price_per_month: "价格",
            contract_period: "合同期限",
            free_charging_time_limit: "免费充电时间",
            overtime_charge_price: "超时价格",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_pla: "请填写ID",
                name: "请填写计划名称",
                price_per_month: "请填写价格",
                contract_period: "请填写合同期限",
                free_charging_time_limit: "请填写免费充电时间",
                overtime_charge_price: "请填写超时价格",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    region: {
        name: {
            title: "区域",
            detailTitle: "区域详情",
            editTitle: "编辑区域"
        },
        table: {
            id_reg: "ID",
            name: "区域名称",
            ac_price_per_electricity: "直流按电量价格",
            ac_price_per_minute: "直流按分钟价格",
            lon: "经度",
            lat: "纬度",
            address: "地址",
            dc_price_per_electricity: "交流按电量价格",
            dc_price_per_minute: "交流按分钟价格",
            tenant_key: "租户识别码",
        },
        field: {
            id_reg: "ID",
            name: "区域名称",
            ac_price_per_electricity: "直流按电量价格",
            ac_price_per_minute: "直流按分钟价格",
            lon: "经度",
            lat: "纬度",
            address: "地址",
            dc_price_per_electricity: "交流按电量价格",
            dc_price_per_minute: "交流按分钟价格",
            tenant_key: "租户识别码",
            images: "图片"
        },
        validate: {
            required: {
                id_reg: "请填写ID",
                name: "请填写区域名称",
                ac_price_per_electricity: "请填写直流按电量价格",
                ac_price_per_minute: "请填写直流按分钟价格",
                lon: "请填写经度",
                lat: "请填写纬度",
                address: "请填写地址",
                dc_price_per_electricity: "请填写交流按电量价格",
                dc_price_per_minute: "请填写交流按分钟价格",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    regionImages: {
        name: {
            title: "充电桩图片",
            detailTitle: "充电桩图片详情",
            editTitle: "编辑充电桩图片"
        },
        table: {
            id_reg_img: "ID",
            id_reg: "所属区域",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        field: {
            id_reg_img: "ID",
            id_reg: "所属区域",
            url: "链接",
            upload_time: "上传时间",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_reg_img: "请填写ID",
                id_reg: "请填写所属区域",
                url: "请填写链接",
                upload_time: "请填写上传时间",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    repairLog: {
        name: {
            title: "维修记录",
            detailTitle: "维修记录详情",
            editTitle: "编辑维修记录"
        },
        table: {
            id_rep_log: "ID",
            id_car: "车辆",
            amount: "金额",
            repair_date: "维修日期",
            remark: "备注",
            tenant_key: "租户识别码",
        },
        field: {
            id_rep_log: "ID",
            id_car: "车辆",
            amount: "金额",
            repair_date: "维修日期",
            remark: "备注",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_rep_log: "请填写ID",
                id_car: "请填写车辆",
                amount: "请填写金额",
                repair_date: "请填写维修日期",
                remark: "请填写备注",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    role: {
        name: {
            title: "角色",
            detailTitle: "角色详情",
            editTitle: "编辑角色"
        },
        table: {
            id_rol: "ID",
            name: "角色",
            remark: "备注",
        },
        field: {
            id_rol: "ID",
            name: "角色",
            remark: "备注",
            authorityList: "权限列表"
        },
        validate: {
            required: {
                id_rol: "请填写ID",
                name: "请填写角色",
                remark: "请填写备注",
            }
        },
    },
    roleAuthority: {
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority"
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "请填写id_rol_aut",
                id_rol: "请填写id_rol",
                id_aut: "请填写id_aut",
            }
        },
    },
    transactionLog: {
        name: {
            title: "事务记录",
            detailTitle: "事务记录详情",
            editTitle: "编辑事务记录"
        },
        table: {
            id_tra_log: "ID",
            id_con: "所属连接端",
            event_time: "发生时间",
            id_tag: "用户标签",
            start_time: "事务开始时间",
            start_value: "开始值",
            stop_time: "事务结束时间",
            stop_value: "结束值",
            stop_reason: "结束原因",
            fail_reason: "失败原因",
            tenant_key: "租户识别码",
            current_electricity: "当前充电电量",
        },
        field: {
            id_tra_log: "ID",
            id_con: "所属连接端",
            event_time: "发生时间",
            id_tag: "用户标签",
            start_time: "事务开始时间",
            start_value: "开始值",
            stop_time: "事务结束时间",
            stop_value: "结束值",
            stop_reason: "结束原因",
            fail_reason: "失败原因",
            tenant_key: "租户识别码",
            current_electricity: "当前充电电量",
        },
        validate: {
            required: {
                id_tra_log: "请填写ID",
                id_con: "请填写所属连接端",
                event_time: "请填写发生时间",
                id_tag: "请填写用户标签",
                start_time: "请填写事务开始时间",
                start_value: "请填写开始值",
                stop_time: "请填写事务结束时间",
                stop_value: "请填写结束值",
                stop_reason: "请填写结束原因",
                fail_reason: "请填写失败原因",
                tenant_key: "请填写租户识别码",
                current_electricity: "请填写当前充电电量",
            }
        },
    },
    user: {
        name: {
            title: "用户",
            detailTitle: "用户详情",
            editTitle: "编辑用户"
        },
        table: {
            id_use: "ID",
            balance: "余额",
            account: "账号",
            password: "密码",
            register_time: "注册时间",
            email: "邮箱",
            token: "令牌",
            salt: "盐",
            tenant_key: "租户识别码",
            phone: "电话",
            register_type: "注册类型",
        },
        field: {
            id_use: "ID",
            balance: "余额",
            account: "账号",
            password: "密码",
            register_time: "注册时间",
            email: "邮箱",
            token: "令牌",
            salt: "盐",
            tenant_key: "租户识别码",
            phone: "电话",
            register_type: "注册类型",
        },
        validate: {
            required: {
                id_use: "请填写ID",
                balance: "请填写余额",
                account: "请填写账号",
                password: "请填写密码",
                register_time: "请填写注册时间",
                email: "请填写邮箱",
                token: "请填写令牌",
                salt: "请填写盐",
                tenant_key: "请填写租户识别码",
                phone: "请填写电话",
                register_type: "请填写注册类型",
            }
        },
    },
    userPlan: {
        name: {
            title: "用户计划",
            detailTitle: "用户计划详情",
            editTitle: "编辑用户计划"
        },
        table: {
            id_use: "用户",
            id_pla: "计划",
            start_date: "合同开始日期",
            tenant_key: "租户识别码",
        },
        field: {
            id_use: "用户",
            id_pla: "计划",
            start_date: "合同开始日期",
            tenant_key: "租户识别码",
        },
        validate: {
            required: {
                id_use: "请填写用户",
                id_pla: "请填写计划",
                start_date: "请填写合同开始日期",
                tenant_key: "请填写租户识别码",
            }
        },
    },
    dashboard: {
        name: {
            totalChargers: "充电桩总数",
            availableChargers: "可用充电桩数",
            inUseChargers: "使用中的充电桩数",
            unavailableChargers: "不可用充电桩数",
            energyUsage: "总使用电量(kWh)",
            totalRevenue: "总收入",
            totalEnergy: "总电量",
            newUser: "新用户数",
        },
        table: {},
        field: {},
        validate: {
            required: {}
        },
    },
    appVersionManage: {
        name: {},
        table: {},
        field: {
            androidVersion: "Android版本",
            androidDownloadLink: "Android下载链接",
            androidComplieNumber: "Android编译次数",
            iosVersion: "IOS版本",
            iosBundleId: "IOS资源号",
            iosComplieNumber: "IOS编译次数",

        },
        validate: {
            required: {
                androidVersion: "请填入Android版本",
                androidDownloadLink: "请填入Android下载链接",
                androidComplieNumber: "请填入Android编译次数",
                iosVersion: "请填入IOS版本",
                iosBundleId: "请填入IOS资源号",
                iosComplieNumber: "请填入IOS编译次数",
            }
        },
    },

    // 租戶管理
    tenantManage: {
        searchForm: {
            dataSourceKey: "租户标识",
            url: "链接地址",
            username: "账户",
            tenant: "租户名",
            isEnable: "是否启用",
            isCreate: "创建标识",
        },
        searchFormPlaceholder: {
            dataSourceKey: "请输入租户标识",
            url: "请输入链接地址",
            username: "请输入账户",
            tenant: "请输入租户名",
            isEnable: "请选择是否启用",
            isCreate: "请选择创建标识",
        },
        // 列表
        table: {
            index: "序号",
            dataSourceKey: "库标识",
            url: "链接地址",
            host: "地址",
            port: "端口",
            dbName: "数据库名",
            username: "账户",
            password: "密码",
            tenant: "租户名",
            isEnable: "是否启用",
            isCreate: "创建标识",
            action: {
                testConn: "连接",
                create: "创建",
                edit: "编辑",
                del: "删除",
            }
        },
        // 表单
        field: {
            id: "ID",
            datasourceKey: "库标识",
            url: "链接地址",
            host: "地址",
            port: "端口",
            dbName: "数据库名",
            username: "用户名",
            password: "密码",
            tenant: "租户名",
            isEnable: "是否启用",
            isCreate: "创建标识",
            remark: "备注",
        },
        // 表单校验
        validate: {
            required: {
                id: "请填写ID",
                dataSourceKey: "库标识",
                url: "请填写链接地址",
                host: "请填写地址",
                port: "请填写端口",
                dbName: "请填写数据库名",
                username: "请填写用户名",
                password: "请填写密码",
                tenant: "请填写租户名",
                isEnable: "请填写租户名",
                remark: "请填写备注",
            }
        }
    },
    // 电桩管理
    pileManage: {
        updateFirmware: {
            identifier: "序列号",
            location: "远程地址",
            retrieveDate: "检索日期",
        },
        uValidate: {
            identifier: "请输入序列号",
            location: "请输入远程地址",
            retrieveDate: "请选择检索日期",
        },
        field: {
            id: "ID",
            identifier: "充电桩序列号",
            reId: "客户编号",
            evName: "充电桩名称",
            evStatus: "当前状态",
            evType: "充电桩类型",
            firmwareVersion: "固件版本",
            lastHeartBeatTime: "最近心跳時間",
            remark: "备注",
            readMeter: "读表方式",
            readMeterList: {
                "0": "差值",
                "1": "仪表值",
            },
            evStatusList: {
                UnEnroll: "未注册",
                Available: "可用的",
                Detected: "待检测",
                UnAvailable: "不可用"
            },
            action: {
                updateFirmware: "更新固件",
                hardReset: "强制重启",
                softReset: "优雅重启",
            },
        },
        validate: {
            id: '请填写ID',
            identifier: '请填写电桩标识符',
            reId: '请填写租户标识',
            evName: '请填写电桩名',
            evStatus: '请选择电桩状态',
            evType: '请选择电桩类型',
            evArgs: '请填写电桩参数',
            remark: '请填写电桩备注',
        },
        // 设置列表
        settingTable: {
            index: "序号",
            key: "配置名",
            readonly: "是否只读",
            value: "配置值",
            action: {
                setting: "设置",
                edit: "编辑",
                del: "删除",
            }
        },
        settingField: {
            key: "配置名",
            readonly: "是否只读",
            value: "配置值",
            required: {
                key: "请填写配置名",
                readonly: "请选择是否只读",
                value: "请填写配置值",
            }
        },
    },
    // 充电记录
    tradeManage: {
        detailTitle: "订单详情",
        tradeStatusList: {
            0: "空闲",
            1: "进行中",
            2: "已完成",
            3: "故障",
        },
        // 列表
        table: {
            index: "序号",
            id: "事务编号",
            tradeNo: "充电卡号",
            identifier: "电桩标识",
            port: "电桩端口",
            startTime: "开始充电时间",
            duration: "充电时长",
            endTime: "预计结束时间",
            actualEndTime: "实际结束时间",
            actualDuration: "实际充电时长",
            chargeType: "充电类型",
            otherArgs: "其他参数",
            tradeStatus: "交易状态",
            pileType: "电桩类型",
            currentElectricity: "耗电量(wh)",

            createBy: "创建者",
            createTime: "创建时间",
            updateBy: "更新者",
            updateTime: "更新时间",
            remark: "备注",

            action: {
                edit: "编辑",
                del: "删除",
                endTransaction: "终止"
            }
        },
        validate: {
            tradeNo: "请填写流水编号",
            identifier: "请填写电桩标识",
            port: "请填写电桩端口",
            startTime: "请选择开始充电时间",
            duration: "请选择充电时长",
            endTime: "请选择预计结束时间",
            actualEndTime: "请选择实际结束时间",
            actualDuration: "请选择实际充电时长",
            chargeType: "请选择充电类型",
            otherArgs: "请填写其他参数",
            tradeStatus: "请选择交易状态",
            pileType: "请选择电桩类型",

            createBy: "创建者",
            createTime: "创建时间",
            updateBy: "更新者",
            updateTime: "更新时间",
            remark: "备注",
        }
    },
    rentalManage: {
        table: {
            id: "ID",
            index: "序号",
            reId: "客户编号",
            reName: "客户名称",
            reIsAuthorized: "是否授权",
            reSecKey: "密钥",
            reAisle: "回调地址",
            tenantKey: "租户标识",
            reLeaseTime: "到期时间",
            createBy: "创建者",
            createTime: "创建时间",
            updateBy: "更新者",
            updateTime: "更新时间",
            remark: "备注",
        },
        validate: {
            id: "ID",
            reId: "请填写客户编号",
            reName: "请填写客户名称",
            reIsAuthorized: "请选择是否授权",
            reSecKey: "请填写密钥",
            reAisle: "请填写回调地址",
            tenantKey: "请填写租户标识",
            reLeaseTime: "请选择到期时间",
            createBy: "请填写创建者",
            createTime: "请选择创建时间",
            updateBy: "请填写更新者",
            updateTime: "请选择更新时间",
            remark: "请填写备注",
        }
    },
    chargerManage: {
        name: {
            start: "开始充电",
            stop: "结束充电",
            setChargingProfile: "设置充电档案",
            clearChargingProfile: "清除充电档案",
        },
        table: {
            id: "ID",
            identifier: "充电桩序列号",
            cName: "端口名字",
            cPort: "电枪端口",
            cPower: "端口功率(kwh)",
            cType: "端口类型",
            cStatus: "端口状态",
            remark: "备注",
            powerLimit: "电流",
        },
        validate: {
            id: "ID",
            identifier: "请输入充电桩序列号",
            cName: "请输入端口名字",
            cPort: "请输入电枪端口",
            cPower: "请输入端口功率",
            cType: "请选择端口类型",
            cStatus: "请选择端口状态",
            remark: "请输入备注",
            powerLimit: "请输入电流",
        },
    },
    DeviceManage: {
        table: {
            id: "设备ID",
            gatewayId: "网关ID",
            nodeId: "节点ID",
            uuid: "设备UUID",
            category: "产品品类",
            categoryName: "产品品类名称",
            name: "设备名称",
            productId: "产品ID",
            productName: "产品名称",
            localKey: "密钥",
            sub: "是否为子设备",
            assetId: "资产ID",
            ownerId: "家庭ID",
            ip: "设备IP",
            lon: "经度",
            lat: "纬度",
            model: "产品型号",
            timeZone: "时区",
            activeTime: "激活时间",
            updateTime: "更新时间",
            createTime: "初次配网时间",
            icon: "设备图标",
            isOnline: "是否在线",
            customName: "自定义名称",
            bindSpaceId: "绑定空间ID",
        },
        validate: {
            id: "设备ID",
            gatewayId: "网关ID",
            nodeId: "节点ID",
            uuid: "设备UUID",
            category: "产品品类",
            categoryName: "产品品类名称",
            name: "设备名称",
            productId: "产品ID",
            productName: "产品名称",
            localKey: "密钥",
            sub: "是否为子设备",
            assetId: "资产ID",
            ownerId: "家庭ID",
            ip: "设备IP",
            lon: "经度",
            lat: "纬度",
            model: "产品型号",
            timeZone: "时区",
            activeTime: "激活时间",
            updateTime: "更新时间",
            createTime: "初次配网时间",
            icon: "设备图标",
            isOnline: "是否在线",
        },
    },
    DeviceLogs: {
        eventType:{
            1: "上线",
            2: "下线",
            3: "设备激活",
            4: "设备重置",
            5: "指令下发",
            6: "固件升级",
            7: "数据点上报",
            8: "设备信号量",
            9: "设备重启",
            10: "定时信息",
        },
        table: {
            deviceId: "设备ID",
            code: "功能点ID",
            value: "事件详情",
            eventTime: "事件时间",
            eventFrom: "事件来源",
            eventId: "事件ID",
            status: "事件状态",
            eventValue: "事件值",
        },
        validate: {
            deviceId: "设备ID",
            types: "事件ID",
            codes: "功能点ID",
            value: "状态值",
            startTime: "开始时间",
            endTime: "结束时间",
        }
    },
}

export default zhCN
