<template>
  <Layout id="Home" has-sider>
    <LayoutSider
      v-model:collapsed="collapsed"
      breakpoint="xs"
      class="home-sider"
    >
      <Nav ref="refNav"></Nav>
    </LayoutSider>
    <Layout>
      <LayoutHeader class="home-header">
        <Row>
          <Col :span="2">

              <MenuUnfoldOutlined v-if="collapsed" @click="() => (collapsed = !collapsed)"/>
              <MenuFoldOutlined v-else @click="() => (collapsed = !collapsed)"/>

          </Col>
          <Col :offset="19" :span="1">
            <LanguageButton />
          </Col>
          <Col :span="2">
            <Dropdown :trigger="['click']">
              <Button type="text">
                <Avatar :src="$store.getters.getUserPortrait"></Avatar>
                <span>{{ $store.getters.getName }}</span>
              </Button>
              <template #overlay>
                <Menu>
                  <!-- <MenuItem key="personal" @click="personal">
                    <UserOutlined />
                    <span>{{ $t("public.name.personal") }}</span>
                  </MenuItem> -->
                  <MenuItem key="reset_password" @click="reset_password">
                    <UserOutlined />
                    <span>{{ $t("public.name.reset_password") }}</span>
                  </MenuItem>
                  <MenuItem key="logout" @click="logout">
                    <LogoutOutlined />
                    <span>{{ $t("public.name.logout") }}</span>
                  </MenuItem>
                </Menu>
              </template>
            </Dropdown>
          </Col>
        </Row>
      </LayoutHeader>
      <LayoutContent class="home-content">
        <router-view />
      </LayoutContent>
      <LayoutFooter class="home-footer">Ttech Global Service Limited. All Rights Reserved 2022</LayoutFooter>
    </Layout>
  </Layout>
  <PersonalEdit ref="refPersonalEdit" />
  <PersonalPassword ref="refPasswordEdit" />
</template>

<script>
import {
  Layout,
  Row,
  Col,
  Dropdown,
  Button,
  Menu,
  Avatar,
  message,
} from "ant-design-vue";
import {
  LogoutOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons-vue";
import Nav from "@/components/Nav.vue";
import PersonalEdit from "@/components/Home/PersonalEdit.vue";
import PersonalPassword from "@/components/Home/PersonalPassword.vue";
import LanguageButton from "@/components/Common/LanguageButton";
import { Logout } from "@/api/employee";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Nav,
    Row,
    Col,
    Layout,
    Dropdown,
    Menu,
    MenuItem: Menu.Item,
    Avatar,
    Button,
    LayoutSider: Layout.Sider,
    LayoutHeader: Layout.Header,
    LayoutContent: Layout.Content,
    LayoutFooter: Layout.Footer,
    LogoutOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PersonalEdit,
    PersonalPassword,
    LanguageButton,
    message,
  },
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    // check login statuc
    checkLogin() {
      // if (this.$store.getters.getToken == "") {
      //   this.$router.push({ name: "Login" });
      // } else {
      //   const api = new apiLogin();
      //   api.get().then((response:any) => {
      //     if (1 != parseInt(response.data.code)) {
      //       this.$store.commit("setToken", "");
      //       this.$store.commit("setUsername", "");
      //       this.$store.commit("setname", "");
      //       this.$store.commit("setPortrait", "");
      //       this.$store.commit("setLastIp", "");
      //       this.$store.commit("setLastTime", 0);
      //       this.$router.push({ name: "Login" });
      //     }
      //   });
      // }
    },
    logout() {
      const api = new Logout();
      api.get().then((response, any) => {
        if (0 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.$store.dispatch("logout");
          this.$router.push({ name: "Login" });
        } else {
          message.error(response.data.msg);
          // this.getCaptcha();
        }
      });
    },
    personal() {
      this.$refs.refPersonalEdit.show();
    },
    reset_password() {
      this.$refs.refPasswordEdit.show();
    },
    // clickItem(item: any) {
    //   if (!item) return false;
    //   switch (item.key) {
    //     case "logout":
    //       this.logout();
    //       break;
    //   }
    // },
    toggleCollapsed() {
      this.$refs.refNav.toggleCollapsed()
    },
    getCollapsed() {
      return this.$refs.refNav.getCollapsed()
    }

  },
  mounted() {
    this.checkLogin();
  },
};
</script>


<style lang="less">
@import url("style.less");
</style>
