<template>
  <Menu id="Nav"
        :theme="$store.getters.getTheme"
        @click="clickItem"
        mode="inline"
        :forceSubMenuRender="true"
        :openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        @openChange="onOpenChange"
  >
    <div class="logo" />
    <MenuItem key="Dashboard">
      <DashboardOutlined />
      <span>{{$t('nav.name.Dashboard')}}</span>
    </MenuItem>
    <template v-for="(item) in navList" :key="item.name">
      <MenuItem v-if="!item.children" :key="item.name">
        <ControlOutlined v-if="item.name === 'Auth'" />
        <TeamOutlined v-else-if="item.name === 'Member'" />
        <UserOutlined v-else-if="item.name === 'Users'" />
        <UserOutlined v-else-if="item.name === 'UserManage'" />
        <UploadOutlined v-else-if="item.name === 'Uploads'" />
        <PhoneOutlined v-else-if="item.name === 'PhoneCountry'" />
        <BankOutlined v-else-if="item.name === 'CompanyManage'" />
        <ControlOutlined v-else />
        <span>{{$t('nav.name.'+item.name)}}</span>
      </MenuItem>
      <MenuSub v-else :key="item.name">
        <template #title>
          <span>
            <ControlOutlined v-if="item.name === 'Auth'" />
            <TeamOutlined v-else-if="item.name === 'Member'" />
            <UserOutlined v-else-if="item.name === 'Users'" />
            <UserOutlined v-else-if="item.name === 'UserManage'" />
            <UploadOutlined v-else-if="item.name === 'Uploads'" />
            <PhoneOutlined v-else-if="item.name === 'PhoneCountry'" />
            <BankOutlined v-else-if="item.name === 'CompanyManage'" />
            <ControlOutlined v-else />
            <span>{{$t('nav.name.'+item.name)}}</span>
          </span>
        </template>
        <MenuItem v-for="v in item.children" :key="v.name">{{$t('nav.name.'+v.name)}}</MenuItem>
      </MenuSub>
    </template>
  </Menu>
</template>

<script>
import { Menu, notification } from "ant-design-vue";
import { LogoutOutlined, UserOutlined, DashboardOutlined, ControlOutlined, TeamOutlined, UploadOutlined, PhoneOutlined, BankOutlined } from "@ant-design/icons-vue";
import { NavList } from "@/api/NavList"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Nav",
  components: {
    LogoutOutlined,
    UserOutlined,
    DashboardOutlined,
    ControlOutlined,
    TeamOutlined,
    UploadOutlined,
    PhoneOutlined,
    BankOutlined,
    Menu,
    MenuItem: Menu.Item,
    MenuSub: Menu.SubMenu,
  },
  data() {
    return {
      navList: undefined,
      openKeys: undefined,
      selectedKeys: undefined,
    };
  },
  methods: {
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.navList.indexOf(latestOpenKey) === -1) {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      } else {
        this.openKeys = openKeys;
      }
    },

    logout() {
      // 清空tenantDb缓存标识
      this.$store.commit("setTenantDb", undefined);
      this.$store.dispatch("logout");
      location.reload();
    },
    clickItem(item) {
      if (!item) return false;
      if (this.$router.hasRoute(item.key)) {
        this.$router.push({
          name: item.key,
        });
      } else {
        notification.warning({
          message: this.$t('public.name.no_page'),
        });
      }
    },
    // 刷新后获取选中的菜单，防止刷新后菜单重置
    defaultSelectedKeys() {
      let rPath = this.$route.path
      // eslint-disable-next-line no-constant-condition
      if ((rPath !== undefined || true || null) || rPath !=='') {
        rPath = rPath.replace('/', '')
      }
      const Menus = this.navList
      let pd = true
      for (let i = 0; i < Menus.length; i++) {
        const a = Menus[i]
        if (a.children) {
          for (let y = 0; y < a.children.length; y++) {
            const t = a.children[y].name
            if (t === rPath) {
              pd = false
              this.openKeys = [a.name, t]
              this.selectedKeys = [a.name, t]
            }
          }
        }
      }
      if (pd) {
        this.openKeys = []
        this.selectedKeys = []
      }
    },
    get_user_nav() {
      //此处配置角色路由，可从后台获取，可前端写死
      //this.navList = [
            //  { name:"authorityManage"},
      //  { name:"balanceLogManage"},
      //  { name:"carManage"},
      //  { name:"carImagesManage"},
      //  { name:"chargeStationImagesManage"},
      //  { name:"chargingStationManage"},
      //  { name:"connectorManage"},
      //  { name:"employeeManage"},
      //  { name:"orderManage"},
      //  { name:"planManage"},
      //  { name:"regionManage"},
      //  { name:"repairLogManage"},
      //  { name:"roleManage"},
      //  { name:"userManage"},
      //  { name:"userPlanManage"},
      //]
      let api = new NavList();
      api.get().then((response) => {
        if (0 === parseInt(response.data.code)) {
          this.navList = response.data.data;
        } else {
          this.navList = [];
        }
        this.defaultSelectedKeys();
      });
      //直接读取用户type来区分菜单
      // var type = this.$store.getters.getType
      // //后台人员
      // if(type === 0){
      //   this.navList = [{ name:"userManage"},{ name:"CompanyManage"}]
      // }else{
      //   this.navList = [{ name:"CompanyManage"}]
      // }
    },
  },
  mounted() {
    this.get_user_nav();
  },
}
</script>


<style lang="less">
.logo {
  height    : 32px;
  background: rgba(255, 255, 255, 0.2);
  margin    : 16px;
}
</style>
