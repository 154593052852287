import SetChargingProfile from "@/views/Home/tenantSetting/chargerManage/SetChargingProfile.vue"

const enUS = {
    public: {
        name: {
            index: "Index",
            ID: "ID",
            status: "State",
            hello: "Hello",
            login: "Login",
            logout: "Logout",
            username: "Username",
            email: "E-mail",
            password: "Password",
            confirm_password: "Confirm Password",
            reset_password: "Reset Password",
            captcha: "Captcha",
            more: "More",
            add: "Add",
            edit: "Edit",
            del: "Delete",
            setting: "Setting",
            dels: "Batch Delete",
            detail: "Detail",
            yes: "Yes",
            no: "No",
            empty: "Empty",
            are_you_sure_delete: "Are you Sure Want To Delete This Item",
            action_cannot_resumed: "This Action Cannot Be Resumed",
            are_you_sure_operate: "are you sure operate",
            search: "Search",
            reset: "Reset",
            spread: "Spread",
            retract: "Retract",
            show: "Show",
            no_page: "No Page",
            service: "Service",
            phone_country_code: "Phone Country Code",
            name: "Name",
            phone: "Phone",
            sex: "Sex",
            country: "Coutry",
            address: "Address",
            birthday: "Birthday",
            remark: "Remark",
            personal: "Personal",
            enclosure: "Enclosure",
            logo: "Logo",
            submit: "Submit",
            batchDelete: "Batch Delete",
            areYouSure:"Whether to confirm",
            operateRemind: "The current operation is sensitive, please be cautious",
        },
        language: {
            zhTW: "Chinese (Traditional)",
            enUS: "English",
            zhCN: "Chinese (Simplified)",
        },
        isTrue: {
            0: "No",
            1: "Yes",
        },
        status: {
            0: "Unknown",
            1: "Normal",
            2: "Disable",
            3: "Delete",
        },
        sex: {
            1: "Man",
            2: "Woman",
            3: "Unknown"
        },
        placeholder: {
            please_select: "Please Select",
        },
        table: {
            name: "Name",
            status: "State",
            action: "Action",
            content: "Content",
            created_at: "Create Time",
            image: "Image",
            email: "E-mail",
            order_id: "Order",
            sex: "Sex",
            last_ip: "Last Ip",
            phone_country_code: "Please Select Phone Country Code",
            phone: "Phone",
        },
        field: {
            name: "Name",
            username: "Username",
            content: "Content",
            phone_country_code: "Phone Country Code",
            phone: "Phone",
            email: "E-mail",
            order_id: "Order",
            portrait: "Portrait",
            status: "State",
            image: "Image",
            sex: "Sex",
        },
        validate: {
            required: {
                id: "Lack ID",
                username: "Please Entry User Name",
                password: "Please Entry Password",
                repassword: "Please Repeat password",
                name: "Please Entry Name",
                phone: "Please Entry Phone",
                email: "Please Entry E-mail",
                portrait: "Please Upload Portrait",
            },
            tips: {
                inconsistent_password: "Inconsistent password",
                length_should: "Length Should Be {min} To {max}"
            }
        }
    },
    language: {
        zhTW: "Chinese(Traditional)",
        enUS: "English",
        zhCN: "Chinese (Simplified)"
    },
    nav: {
        name: {
            Dashboard: "Dashboard",
            AuthorityManage: "AutorityManage",
            TenantManage: "TenantManage",
            BalanceLogManage: "Balance LogManage",
            CarManage: "CarManage",
            CarImagesManage: "Car ImagesManage",
            ChargeStationImagesManage: "Charge Point ImageManage",
            ChargingStationManage: "Charge PointManage",
            ConnectorManage: "ConnectorManage",
            EmployeeManage: "OperatorManage",
            EventLogManage: "Event LogManage",
            MeterManage: "MeterManage",
            OrderManage: "OrderManage",
            PlanManage: "PlanManage",
            RegionManage: "RegionManage",
            RegionImagesManage: "Region ImageManage",
            RepairLogManage: "Repair LogManage",
            RoleManage: "RoleManage",
            TransactionLogManage: "Transaction LogManage",
            UserManage: "UserManage",
            UserPlanManage: "User PlanManage",
            GetConfiguration: "Get Configuration",
            DataTransfer: "Data Transfer",
            TenantManagement: "Tenant Management",
            ChargerSetting: "Charger Setting",
            AppManagement: "App Management",
            LogManagement: "Log Management",
            AppVersionManage: "App Version Manage",
            RentalManage: "Tenant Manage",
            PileManage: "Charge Station Manage",
            ChargerManage: "Charger Manage",
            TradeManage: "Trade Manage",

            TuyaManagement: "Tuya",
            DeviceManage: "Device Manage",
            DeviceLogs: "Device logs",
        },
        table: {
            order_id: "Order ID",
            url: "Url",
        },
        field: {
            pid: "PID",
            order_id: "Order ID",
            name: "Name",
            url: "Url",
            status: "State",
            icon: "Icon",
        },
    },
    login: {
        validate: {
            required: {
                username: "Please Entry Username",
                email: "Please Entry Email",
                password: "Please Entry Password",
                captcha: "Please Entry Captcha",
            }
        }
    },
    authority: {
        name: {
            title: "Autority",
            detailTitle: "Autority Detail",
            editTitle: "Edit Autority",
        },
        table: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        field: {
            id_aut: "ID",
            a_id_aut: "Parent Authority",
            name: "Authority",
            resource_name: "Resource Name",
            type: "Type",
            sort: "Sort",
        },
        validate: {
            required: {
                id_aut: "ID Required",
                a_id_aut: "Parent Authority Required",
                name: "Authority Required",
                resource_name: "Resource Name Required",
                type: "Type Required",
                sort: "Sort Required",
            }
        },
    },
    balanceLog: {
        name: {
            title: "Balance Log",
            detailTitle: "Balance Log Detail",
            editTitle: "Edit Balance Log",
        },
        table: {
            id_bal_log: "ID",
            id_use: "Uer",
            type: "Type",
            amount: "Amount",
            log_time: "Log Time",
            tenant_key: "Tenant Key",
            status: "Status",
            payment_method: "Payment Method",
        },
        field: {
            id_bal_log: "ID",
            id_use: "Uer",
            type: "Type",
            amount: "Amount",
            log_time: "Log Time",
            tenant_key: "Tenant Key",
            status: "Status",
            payment_method: "Payment Method",
        },
        validate: {
            required: {
                id_bal_log: "ID Required",
                id_use: "Uer Required",
                type: "Type Required",
                amount: "Amount Required",
                log_time: "Log Time Required",
                tenant_key: "Tenant Key Required",
                status: "Status Required",
                payment_method: "Payment Method Required",
            }
        },
    },
    car: {
        name: {
            title: "Car",
            detailTitle: "Car Detail",
            editTitle: "Edit Car",
        },
        table: {
            id_car: "ID",
            id_use: "Uer",
            model: "Model",
            car_license: "License",
            max_years_old: "Max Year",
            manufacture_year: "Manufacture Year",
            maximum_power: "Maximum Power",
            is_second_hand: "Is Second Hand",
            vehicle_inspection_date: "Vehicle Inspection Date",
            insurance_expiry_date: "Insurance Expiry Date",
            tenant_key: "Tenant Key",
        },
        field: {
            id_car: "ID",
            id_use: "Uer",
            model: "Model",
            car_license: "License",
            max_years_old: "Max Year",
            manufacture_year: "Manufacture Year",
            maximum_power: "Maximum Power",
            is_second_hand: "Is Second Hand",
            vehicle_inspection_date: "Vehicle Inspection Date",
            insurance_expiry_date: "Insurance Expiry Date",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_car: "ID Required",
                id_use: "Uer Required",
                model: "Model Required",
                car_license: "License Required",
                max_years_old: "Max Year Required",
                manufacture_year: "Manufacture Year Required",
                maximum_power: "Maximum Power Required",
                is_second_hand: "Is Second Hand Required",
                vehicle_inspection_date: "Vehicle Inspection Date Required",
                insurance_expiry_date: "Insurance Expiry Date Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    carImages: {
        name: {
            title: "Car Images",
            detailTitle: "Car Images Detail",
            editTitle: "Edit Car Images",
        },
        table: {
            id_car_ima: "ID",
            id_car: "Car",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_car_ima: "ID",
            id_car: "Car",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_car_ima: "ID Required",
                id_car: "Car Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    chargeStationImages: {
        name: {
            title: "Charge Point Image",
            detailTitle: "Charge Point Image Detail",
            editTitle: "Edit Charge Point Image",
        },
        table: {
            id_ima: "ID",
            id_cha_poi: "Charge Point",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_ima: "ID",
            id_cha_poi: "Charge Point",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_ima: "ID Required",
                id_cha_poi: "Charge Point Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    chargingStation: {
        name: {
            title: "Charge Point",
            detailTitle: "Charge Point Detail",
            editTitle: "Edit Charge Point",
            quickAdd: "Add Charge Point",
        },
        table: {
            id_cha_poi: "ID",
            id_met: "Meter",
            charge_box_id: "Charge Box Id",
            last_heartbeat_time: "Last Heartbeat Time",
            monthly_fee: "Monthly Fee",
            charger_type: "Charger Type",
            tenant_key: "Tenant Key",
            name: "Name",
            heartbeat_interval: "Heartbeat Interval",
            enable: "Enable",
        },
        field: {
            id_cha_poi: "ID",
            id_met: "Meter",
            charge_box_id: "Charge Box Id",
            last_heartbeat_time: "Last Heartbeat Time",
            monthly_fee: "Monthly Fee",
            charger_type: "Charger Type",
            tenant_key: "Tenant Key",
            name: "Name",
            heartbeat_interval: "Heartbeat Interval",
            enable: "Enable",
        },
        validate: {
            required: {
                id_cha_poi: "ID Required",
                id_met: "Meter Required",
                charge_box_id: "Charge Box Id Required",
                last_heartbeat_time: "Last Heartbeat Time Required",
                monthly_fee: "Monthly Fee Required",
                charger_type: "Charger Type Required",
                tenant_key: "Tenant Key Required",
                name: "Name Required",
                heartbeat_interval: "Heartbeat Interval Required",
                enable: "Enable Required",
            }
        },
    },
    connector: {
        name: {
            title: "Connector",
            detailTitle: "Connector Detail",
            editTitle: "Edit Connector",
            startTransaction: "Start Transaction",
            stopTransaction: "Stop Transaction",
        },
        table: {
            id_con: "ID",
            id_cha_poi: "Charge Point",
            name: "Name",
            connector_id: "Connector Id",
            status: "Status",
            tenant_key: "Tenant Key",
            power: "Power",
            type: "Type",
        },
        field: {
            id_con: "ID",
            id_cha_poi: "Charge Point",
            name: "Name",
            connector_id: "Connector Id",
            status: "Status",
            tenant_key: "Tenant Key",
            power: "Power",
            type: "Type",
        },
        validate: {
            required: {
                id_con: "ID Required",
                id_cha_poi: "Charge Point Required",
                name: "Name Required",
                connector_id: "Connector Id Required",
                status: "Status Required",
                tenant_key: "Tenant Key Required",
                power: "Power Required",
                type: "Type Required",
            }
        },
    },
    employee: {
        name: {
            title: "Operator",
            detailTitle: "Operator Detail",
            editTitle: "Edit Operator",
        },
        table: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            remark: "Remark",
            status: "Status",
            name: "Name",
            commission_rate: "Commission Rate",
            yedpay_api_key: "Yedpay API Key",
            yedpay_sign_key: "Yedpay Sign Key",
            tenant_key: "Tenant Key",
        },
        field: {
            id_emp: "ID",
            id_rol: "Role",
            account: "Account",
            password: "Password",
            salt: "Salt",
            last_login_time: "Last Login Time",
            register_time: "Register Time",
            login_failure_counter: "Login Failure Counter",
            email: "Email",
            remark: "Remark",
            status: "Status",
            name: "Name",
            commission_rate: "Commission Rate",
            yedpay_api_key: "Yedpay API Key",
            yedpay_sign_key: "Yedpay Sign Key",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_emp: "ID Required",
                id_rol: "Role Required",
                account: "Account Required",
                password: "Password Required",
                salt: "Salt Required",
                last_login_time: "Last Login Time Required",
                register_time: "Register Time Required",
                login_failure_counter: "Login Failure Counter Required",
                email: "Email Required",
                remark: "Remark Required",
                status: "Status Required",
                name: "Name Required",
                commission_rate: "Commission Rate Required",
                yedpay_api_key: "Yedpay API Key Required",
                yedpay_sign_key: "Yedpay Sign Key Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    eventLog: {
        name: {
            title: "Event Log",
            detailTitle: "Event Log Detail",
            editTitle: "Edit Event Log",
        },
        table: {
            id_env_log: "ID",
            event_time: "Event Time",
            log: "Log",
            tenant_key: "Tenant Key",
        },
        field: {
            id_env_log: "ID",
            event_time: "Event Time",
            log: "Log",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_env_log: "ID Required",
                event_time: "Event Time Required",
                log: "Log Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    meter: {
        name: {
            title: "Meter",
            detailTitle: "Meter Detail",
            editTitle: "Edit Meter",
        },
        table: {
            id_met: "ID",
            id_reg: "Region",
            name: "Meter Name",
            current_threshold: "Current Threshold",
            tenant_key: "Tenant Key",
        },
        field: {
            id_met: "ID",
            id_reg: "Region",
            name: "Meter Name",
            current_threshold: "Current Threshold",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_met: "ID Required",
                id_reg: "Region Required",
                name: "Meter Name Required",
                current_threshold: "Current Threshold Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    order: {
        name: {
            title: "Order",
            detailTitle: "Order Detail",
            editTitle: "Edit Order",
        },
        table: {
            id_ord: "ID",
            id_car: "Car",
            id_con: "Connector",
            order_time: "Order Time",
            type: "Type",
            amount: "Amount",
            charge_start_time: "Charge Start Time",
            charge_end_time: "Charge End Time",
            status: "Status",
            payment_time: " Payemnt TIme",
            electricity: "Electricity",
            ref_id_pla: "Plan",
            price: "Price",
            commission_rate: "Commission Rate",
            tenant_key: "Tenant Key",
            id_tra_log: "TransactionLogId",
            charge_minutes: "ChargeMinutes",
            pay_type: "PayType",
            payment_method: "Payment Method",
        },
        field: {
            id_ord: "ID",
            id_car: "Car",
            id_con: "Connector",
            order_time: "Order Time",
            type: "Type",
            amount: "Amount",
            charge_start_time: "Charge Start Time",
            charge_end_time: "Charge End Time",
            status: "Status",
            payment_time: " Payemnt TIme",
            electricity: "Electricity",
            ref_id_pla: "Plan",
            price: "Price",
            commission_rate: "Commission Rate",
            tenant_key: "Tenant Key",
            id_tra_log: "TransactionLogId",
            charge_minutes: "ChargeMinutes",
            pay_type: "PayType",
            payment_method: "Payment Method",
        },
        validate: {
            required: {
                id_ord: "ID Required",
                id_car: "Car Required",
                id_con: "Connector Required",
                order_time: "Order Time Required",
                type: "Type Required",
                amount: "Amount Required",
                charge_start_time: "Charge Start Time Required",
                charge_end_time: "Charge End Time Required",
                status: "Status Required",
                payment_time: " Payemnt TIme Required",
                electricity: "Electricity Required",
                ref_id_pla: "Plan Required",
                price: "Price Required",
                commission_rate: "Commission Rate Required",
                tenant_key: "Tenant Key Required",
                id_tra_log: "TransactionLogId Required",
                charge_minutes: "ChargeMinutes Required",
                pay_type: "PayType Required",
                payment_method: "Payment Method Required",
            }
        },
    },
    plan: {
        name: {
            title: "Plan",
            detailTitle: "Plan Detail",
            editTitle: "Edit Plan",
        },
        table: {
            id_pla: "ID",
            name: "Plan Name",
            price_per_month: "Price",
            contract_period: "Contract Period",
            free_charging_time_limit: "Free Charging Time",
            overtime_charge_price: "Overtime Price",
            tenant_key: "Tenant Key",
        },
        field: {
            id_pla: "ID",
            name: "Plan Name",
            price_per_month: "Price",
            contract_period: "Contract Period",
            free_charging_time_limit: "Free Charging Time",
            overtime_charge_price: "Overtime Price",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_pla: "ID Required",
                name: "Plan Name Required",
                price_per_month: "Price Required",
                contract_period: "Contract Period Required",
                free_charging_time_limit: "Free Charging Time Required",
                overtime_charge_price: "Overtime Price Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    region: {
        name: {
            title: "Region",
            detailTitle: "Region Detail",
            editTitle: "Edit Region",
        },
        table: {
            id_reg: "ID",
            name: "Region Name",
            ac_price_per_electricity: "AC Price Per Electricity",
            ac_price_per_minute: "AC Price Per Minute",
            lon: "Lon",
            lat: "Lat",
            address: "Address",
            dc_price_per_electricity: "DC Price Per Electricity",
            dc_price_per_minute: "DC Price Per Minute",
            tenant_key: "Tenant Key",
        },
        field: {
            id_reg: "ID",
            name: "Region Name",
            ac_price_per_electricity: "AC Price Per Electricity",
            ac_price_per_minute: "AC Price Per Minute",
            lon: "Lon",
            lat: "Lat",
            address: "Address",
            dc_price_per_electricity: "DC Price Per Electricity",
            dc_price_per_minute: "DC Price Per Minute",
            tenant_key: "Tenant Key",
            images: "Images"
        },
        validate: {
            required: {
                id_reg: "ID Required",
                name: "Region Name Required",
                ac_price_per_electricity: "AC Price Per Electricity Required",
                ac_price_per_minute: "AC Price Per Minute Required",
                lon: "Lon Required",
                lat: "Lat Required",
                address: "Address Required",
                dc_price_per_electricity: "DC Price Per Electricity Required",
                dc_price_per_minute: "DC Price Per Minute Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    regionImages: {
        name: {
            title: "Region Image",
            detailTitle: "Region Image Detail",
            editTitle: "Edit Region Image",
        },
        table: {
            id_reg_img: "ID",
            id_reg: "Region",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        field: {
            id_reg_img: "ID",
            id_reg: "Region",
            url: "Url",
            upload_time: "Upload Time",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_reg_img: "ID Required",
                id_reg: "Region Required",
                url: "Url Required",
                upload_time: "Upload Time Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    repairLog: {
        name: {
            title: "Repair Log",
            detailTitle: "Repair Log Detail",
            editTitle: "Edit Repair Log",
        },
        table: {
            id_rep_log: "ID",
            id_car: "Car",
            amount: "Amount",
            repair_date: "Repair Date",
            remark: "Remark",
            tenant_key: "Tenant Key",
        },
        field: {
            id_rep_log: "ID",
            id_car: "Car",
            amount: "Amount",
            repair_date: "Repair Date",
            remark: "Remark",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_rep_log: "ID Required",
                id_car: "Car Required",
                amount: "Amount Required",
                repair_date: "Repair Date Required",
                remark: "Remark Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    role: {
        name: {
            title: "Role",
            detailTitle: "Role Detail",
            editTitle: "Edit Role",
        },
        table: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
        },
        field: {
            id_rol: "ID",
            name: "Role",
            remark: "Remark",
            authorityList: "Authority List"
        },
        validate: {
            required: {
                id_rol: "ID Required",
                name: "Role Required",
                remark: "Remark Required",
            }
        },
    },
    roleAuthority: {
        name: {
            title: "RoleAuthority",
            detailTitle: "RoleAuthority Detail",
            editTitle: "Edit RoleAuthority",
        },
        table: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        field: {
            id_rol_aut: "id_rol_aut",
            id_rol: "id_rol",
            id_aut: "id_aut",
        },
        validate: {
            required: {
                id_rol_aut: "id_rol_aut Required",
                id_rol: "id_rol Required",
                id_aut: "id_aut Required",
            }
        },
    },
    transactionLog: {
        name: {
            title: "Transaction Log",
            detailTitle: "Transaction Log Detail",
            editTitle: "Edit Transaction Log",
        },
        table: {
            id_tra_log: "ID",
            id_con: "Connector IDt",
            event_time: "Event Time",
            id_tag: "ID Tag",
            start_time: "Start Time",
            start_value: "Start Value",
            stop_time: "Stop Time",
            stop_value: "Stop Value",
            stop_reason: "Stop Reason",
            fail_reason: "Fail Reason",
            tenant_key: "Tenant Key",
            current_electricity: "CurrentElectircity",
        },
        field: {
            id_tra_log: "ID",
            id_con: "Connector IDt",
            event_time: "Event Time",
            id_tag: "ID Tag",
            start_time: "Start Time",
            start_value: "Start Value",
            stop_time: "Stop Time",
            stop_value: "Stop Value",
            stop_reason: "Stop Reason",
            fail_reason: "Fail Reason",
            tenant_key: "Tenant Key",
            current_electricity: "CurrentElectircity",
        },
        validate: {
            required: {
                id_tra_log: "ID Required",
                id_con: "Connector IDt Required",
                event_time: "Event Time Required",
                id_tag: "ID Tag Required",
                start_time: "Start Time Required",
                start_value: "Start Value Required",
                stop_time: "Stop Time Required",
                stop_value: "Stop Value Required",
                stop_reason: "Stop Reason Required",
                fail_reason: "Fail Reason Required",
                tenant_key: "Tenant Key Required",
                current_electricity: "CurrentElectircity Required",
            }
        },
    },
    user: {
        name: {
            title: "User",
            detailTitle: "User Detail",
            editTitle: "Edit User",
        },
        table: {
            id_use: "ID",
            balance: "Balance",
            account: "Account",
            password: "Password",
            register_time: "Register Time",
            email: "Email",
            token: "Token",
            salt: "Salt",
            tenant_key: "Tenant Key",
            phone: "Phone",
            register_type: "Register Type",
        },
        field: {
            id_use: "ID",
            balance: "Balance",
            account: "Account",
            password: "Password",
            register_time: "Register Time",
            email: "Email",
            token: "Token",
            salt: "Salt",
            tenant_key: "Tenant Key",
            phone: "Phone",
            register_type: "Register Type",
        },
        validate: {
            required: {
                id_use: "ID Required",
                balance: "Balance Required",
                account: "Account Required",
                password: "Password Required",
                register_time: "Register Time Required",
                email: "Email Required",
                token: "Token Required",
                salt: "Salt Required",
                tenant_key: "Tenant Key Required",
                phone: "Phone Required",
                register_type: "Register Type Required",
            }
        },
    },
    userPlan: {
        name: {
            title: "User Plan",
            detailTitle: "User Plan Detail",
            editTitle: "Edit User Plan",
        },
        table: {
            id_use: "Uer",
            id_pla: "Plan",
            start_date: "Start Date",
            tenant_key: "Tenant Key",
        },
        field: {
            id_use: "Uer",
            id_pla: "Plan",
            start_date: "Start Date",
            tenant_key: "Tenant Key",
        },
        validate: {
            required: {
                id_use: "Uer Required",
                id_pla: "Plan Required",
                start_date: "Start Date Required",
                tenant_key: "Tenant Key Required",
            }
        },
    },
    dashboard: {
        name: {
            totalChargers: "Total Chargers",
            availableChargers: "Available Chargers",
            inUseChargers: "In Use Chargers",
            unavailableChargers: "Unavailable Chargers",
            energyUsage: "Energy Usage(kWh)",
            totalRevenue: "Total Revenue",
            totalEnergy: "Total Energy",
            newUser: "New User",
        },
        table: {},
        field: {},
        validate: {
            required: {}
        },
    },
    appVersionManage: {
        name: {},
        table: {},
        field: {
            androidVersion: "Android Version",
            androidDownloadLink: "Android Download Link",
            androidComplieNumber: "Android Complie Number",
            iosVersion: "IOS Version",
            iosBundleId: "IOS Bundle Id",
            iosComplieNumber: "IOS Complie Number",

        },
        validate: {
            required: {
                androidVersion: "Android Version Required",
                androidDownloadLink: "Android Download Link Required",
                androidComplieNumber: "Android Complie Number Required",
                iosVersion: "IOS Version Required",
                iosBundleId: "IOS Bundle Id Required",
                iosComplieNumber: "IOS Complie Number Required",
            }
        },
    },
    // 租戶管理
    tenantManage: {
        name: {
            title: "Permissions",
            detailTitle: "Details",
            editTitle: "Edit"
        },
        searchForm: {
            dataSourceKey: "DataSourceKey",
            url: "Url",
            username: "UserName",
            tenant: "Tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
        },
        searchFormPlaceholder: {
            dataSourceKey: "Please enter DataSourceKey",
            url: "Please enter the link address",
            username: "Please enter account",
            tenant: "Please enter tenant name",
            isEnable: "Please choose whether to enable",
            isCreate: "Please choose to create a logo",
        },
        // 列表
        table: {
            index: "Index",
            dataSourceKey: "DataSourceKey",
            url: "Url",
            host: "Host",
            port: "Port",
            dbName: "DBName",
            username: "username",
            password: "password",
            tenant: "tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
            action: {
                testConn: "Conn",
                create: "Create",
                edit: "Edit",
                del: "Delete",
            }
        },
        // 表单
        field: {
            id: "ID",
            datasourceKey: "DatasourceKey",
            url: "Url",
            host: "Host",
            port: "Port",
            dbName: "DbName",
            username: "UserName",
            password: "password",
            tenant: "tenant",
            isEnable: "IsEnable",
            isCreate: "IsCreate",
            remark: "Remark",
        },
        // 表单校验
        validate: {
            required: {
                id: "Please fill in the ID",
                dataSourceKey: "Please fill in the DataSourceKey",
                url: "Please fill in the link Url",
                host: "Please fill in the Host",
                port: "Please fill in the Port",
                dbName: "Please fill in the database name",
                username: "please enter username",
                password: "Please fill in the password",
                tenant: "Please fill in the tenant name",
                isEnable: "Please select an active state",
                remark: "Please fill in the remarks",
            }
        }
    },
    tradeManage: {
        detailTitle: "Trade Manage",
        tradeStatusList: {
            0: "free time",
            1: "In progress",
            2: "Completed",
            3: "fault",
        },
        table: {
            index: "ID",
            id: "Transaction Number",
            tradeNo: "Charging Card",
            identifier: "Charge Station Serial Number",
            port: "Port",
            startTime: "Start Charging Time",
            duration: "Duration",
            endTime: "Stop Charging Time",
            actualEndTime: "Actual End Time",
            actualDuration: "Actual Duration",
            chargeType: "Charge Type",
            otherArgs: "Other Args",
            tradeStatus: "Status",
            pileType: "Charge Station Type",
            currentElectricity: "Electricity(wh)",

            createBy: "Create By",
            createTime: "Create At",
            updateBy: "Update By",
            updateTime: "Update Time",
            remark: "Remark",

            action: {
                edit: "Edit",
                del: "Delete",
                endTransaction: "END",
            }
        },
        validate: {
            tradeNo: "Trade No Required",
            identifier: "Charge Station Serial Number Required",
            port: "Please fill in the pile port",
            startTime: "Start Charging Time Required",
            duration: "Duration Required",
            endTime: "Stop Charging Time Required",
            actualEndTime: "Actual End Time Required",
            actualDuration: "Actual Duration Required",
            chargeType: "Charge Type Required",
            otherArgs: "Other Args Required",
            tradeStatus: "Status Required",
            pileType: "Charge Station Type Required",

            createBy: "Create By",
            createTime: "Create At",
            updateBy: "Update By",
            updateTime: "Update Time",
            remark: "Remark",
        }
    },
    rentalManage: {
        table: {
            id: "ID",
            index: "Serial Number",
            reId: "Customer Number",
            reName: "Customer Name",
            reIsAuthorized: "Whether Authorize",
            reSecKey: "Sec Key",
            reAisle: "Callback Address",
            tenantKey: "Tenant Key",
            reLeaseTime: "Expiry Time",
            createBy: "Created By",
            createTime: "Create Time",
            updateBy: "Update By",
            updateTime: "Update Time",
            remark: "Remark",
        },
        validate: {
            id: "ID",
            reId: "Please fill in the customer number",
            reName: "Please fill in the customer name",
            reIsAuthorized: "Please select whether to authorize or not",
            reSecKey: "Please fill in the key",
            reAisle: "Please fill in the callback address",
            tenantKey: "Fill in the tenant identity",
            reLeaseTime: "Please select an expiration time",
            createBy: "Please fill in the creator",
            createTime: "Please select a creation time",
            updateBy: "Please fill in the updated by",
            updateTime: "Please select an update time",
            remark: "Please fill in the comments",
        }
    },

    pileManage: {
        updateFirmware: {
            identifier: "Serial number",
            location: "Remote address",
            retrieveDate: "Date of retrieval",
        },
        uValidate: {
            identifier: "Please enter the serial number",
            location: "Please enter a remote address",
            retrieveDate: "Please select a search date",
        },
        field: {
            id: "ID",
            identifier: "Identifier",
            reId: "Tenant Key",
            evName: "Charge Station Name",
            evStatus: "Status",
            evType: "Charge Station Type",
            firmwareVersion: "Firmware Version",
            lastHeartBeatTime: "Lattest Heart Beat",
            remark: "Remark",
            readMeter: "Read Meter",
            readMeterList: {
                "0": "Difference",
                "1": "Meter Value",
            },
            evStatusList: {
                UnEnroll: "UnEnroll",
                Available: "Available",
                Detected: "Detected",
                UnAvailable: "UnAvailable"
            },
            action: {
                updateFirmware: "Update Firmware",
                hardReset: "Hard Reset",
                softReset: "Soft Reset",
            },
        },
        validate: {
            id: 'Please fill in the ID',
            identifier: 'Please fill in the pile identifier',
            reId: 'Fill in the tenant identity',
            evName: 'Please fill in the pile name',
            evStatus: 'Please select the pile status',
            evType: 'Please select the pile type',
            evArgs: 'Please fill in the pile parameters',
            remark: 'Please fill in the pile remarks',
        },
        // 设置列表
        settingTable: {
            index: "ID",
            key: "Setting key",
            readonly: "readonly",
            value: "Setting value",
            action: {
                setting: "setting",
                edit: "edit",
                del: "del",
            }
        },
        // 设置表单
        settingField: {
            key: "Setting key",
            readonly: "readonly",
            value: "Setting value",
            required: {
                key: "Setting key Required",
                readonly: "readonly Required",
                value: "Setting value Required",
            }
        },
    },
    chargerManage: {
        name: {
            start: "Start Charging",
            stop: "Stop Charging",
            setChargingProfile: "Set Charging Profile",
            clearChargingProfile: "Clear Charging Profile",
        },
        table: {
            id: "ID",
            identifier: "Identifier",
            cName: "Charger Name",
            cPort: "Charger Port",
            cPower: "Power(kwh)",
            cType: "Type",
            cStatus: "Status",
            remark: "Remark",
            powerLimit: "Power Limit",
        },
        validate: {
            id: "ID",
            identifier: "Please enter the charging station serial number",
            cName: "Please enter a port name",
            cPort: "Please enter the gun port",
            cPower: "Please enter the port power",
            cType: "Please select a port type",
            cStatus: "Please select the port status",
            remark: "Please enter a comment",
            powerLimit: "Please enter power limit"
        },
    },
    DeviceManage: {
        table: {
            id: "id",
            gatewayId: "gatewayId",
            nodeId: "nodeId",
            uuid: "uuid",
            category: "category",
            categoryName: "categoryName",
            name: "name",
            productId: "productId",
            productName: "productName",
            localKey: "localKey",
            sub: "sub",
            assetId: "assetId",
            ownerId: "ownerId",
            ip: "ip",
            lon: "lon",
            lat: "lat",
            model: "model",
            timeZone: "timeZone",
            activeTime: "activeTime",
            updateTime: "updateTime",
            createTime: "createTime",
            icon: "icon",
            isOnline: "isOnline",
            customName: "customName",
            bindSpaceId: "bindSpaceId",
        },
        validate: {
            id: "id",
            gatewayId: "gatewayId",
            nodeId: "nodeId",
            uuid: "uuid",
            category: "category",
            categoryName: "categoryName",
            name: "name",
            productId: "productId",
            productName: "productName",
            localKey: "localKey",
            sub: "sub",
            assetId: "assetId",
            ownerId: "ownerId",
            ip: "ip",
            lon: "lon",
            lat: "lat",
            model: "model",
            timeZone: "timeZone",
            activeTime: "activeTime",
            updateTime: "updateTime",
            createTime: "createTime",
            icon: "icon",
            isOnline: "isOnline",
        },
    },
    DeviceLogs: {
        eventType:{
            1: "Go live",
            2: "Offline",
            3: "Device activation",
            4: "Device reset",
            5: "Command issuance",
            6: "Firmware upgrade",
            7: "Data point reporting",
            8: "Device semaphore",
            9: "Device restart",
            10: "Timed information",
        },
        table: {
            deviceId: "deviceId",
            code: "Code",
            value: "Value",
            eventTime: "EventTime",
            eventFrom: "EventFrom",
            eventId: "EventId",
            status: "Status",
            eventValue: "EventValue",
        },
        validate: {
            deviceId: "deviceId",
            types: "EventId",
            codes: "Code",
            value: "value",
            startTime: "startTime",
            endTime: "endTime",
        }
    },
}
export default enUS